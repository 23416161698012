<template>
  <section class="fill">
    <el-progress
      v-if="status"
      :percentage="percentage"
      :stroke-width="24"
      :text-inside="true"
      :status="status"
    ></el-progress>
    <el-progress
      v-if="!status"
      :percentage="percentage"
      :stroke-width="24"
      :text-inside="true"
    ></el-progress>
  </section>
</template>

<script>
export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      default: 0
    },
    colorOverride: {
      required: false,
      type: String,
      default: undefined
    }
  },

  computed: {
    status() {
      let color = undefined;
      if (this.percentage > 0 && this.percentage <= 33) {
        color = 'exception';
      }
      if (this.percentage >= 100) {
        color = 'success';
      }
      if (this.colorOverride) {
        color = this.colorOverride;
      }
      return color;
    }
  }
};
</script>

<style>
.fill {
  width: 100%;
}
</style>
