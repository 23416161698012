<template>
  <section
    class="flex-row bg-white rounded border-4 overflow-hidden mb-2"
    @click="
      $router.push({ name: 'my-course-show', params: { mcid: course.id } })
    "
  >
    <section class="flex-column">
      <div name="course-name">
        <b>{{ course.title.filter(t => t.lang === $i18n.locale)[0].value }}</b>
      </div>
      <div
        name="course-description"
        class="text-xs"
        v-html="
          course.description.filter(t => t.lang === $i18n.locale)[0].value
        "
      ></div>
      <div name="course-building">{{ course.buildingName }}</div>
      <div v-if="course.validUntil">
        {{
          `
          ${$t('misc.expiration')}: ${$d(
            new Date(course.validUntil),
            'short',
            $i18n.locale === 'no' ? 'no' : 'en'
          )}`
        }}
      </div>
      <div class="whitespace" v-if="!course.validUntil"></div>
      <div name="completed" v-if="course.completed">
        {{
          `
          ${$t('misc.completed')}: 
          ${$d(
            new Date(course.completed),
            'short',
            $i18n.locale === 'no' ? 'no' : 'en'
          )}
          `
        }}
      </div>
      <div name="completed" v-if="!course.completed">
        {{ $t(getNotCompleteLabel()) }}
      </div>
      <ui-button
        v-if="showCompleteExam()"
        type="green"
        @click.prevent="navigateToExam($event)"
        >{{ $t('models.exam') }}</ui-button
      >
      <div>
        <ui-progress
          :percentage="getProgress()"
          :colorOverride="getColor()"
        ></ui-progress>
      </div>
    </section>
  </section>
</template>

<script>
import UiProgress from '@/components/ui/UiProgress';
import UiButton from '@/components/ui/UiButton';
export default {
  props: {
    course: {
      type: Object,
      required: true
    }
  },

  components: {
    UiButton,
    UiProgress
  },

  methods: {
    getProgress() {
      let p = 0;
      if (this.course.completed) {
        p = 100;
      } else if (
        this.course.lessons &&
        this.course.lessons.length > 0 &&
        this.course.userLessons &&
        this.course.userLessons.length > 0
      ) {
        p = Math.floor(
          (this.course.userLessons.length / this.course.lessons.length) * 100
        );
      }
      return p;
    },

    getColor() {
      if (this.course.hasExam && !this.course.completed) {
        return 'warning';
      } else {
        return undefined;
      }
    },

    getNotCompleteLabel() {
      if (this.course.hasExam) {
        return 'misc.exam_not_completed';
      } else {
        return 'misc.not-completed';
      }
    },

    showCompleteExam() {
      return (
        this.course.hasExam &&
        !this.course.completed &&
        this.course.lessons.length === this.course.userLessons.length
      );
    },

    navigateToExam(event) {
      event.stopPropagation();
      this.$router.push({
        name: 'my-course-exam',
        params: { mcid: this.course.id }
      });
    }
  }
};
</script>

<style scoped>
.flex-row {
  display: flex;
  flex-direction: row;
  border: 0 solid #e2e8f0;
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 100%;
  @apply p-4;
}

.flex-column div {
  @apply p-2;
}

.whitespace {
  min-height: 2rem;
}
</style>
